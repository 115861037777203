<template>
  <!-- Begin Agogis.Widget.registerCourse -->
  <!-- Felder gemäss: C:\glit\gl it gmbh\Data - Dokumente\Kunden\Agogis\#Projects\agogis.ch\30 Umsetzung\20211006_ToccoFilterKriterienFuerWidgets.xlsx -->
  <!-- Mockup https://balsamiq.cloud/sdd5gk8/p69siy9/r44B7 -->
  <div class="form angebote anmeldung pWidget" :data-course-id="courseId">
    <div v-if="isLoading">
      <p-progress-linear indeterminate></p-progress-linear>
    </div>
    <div v-else-if="!course">
      <h3>{{ $t('widget.registerCourse.courseNotFound') }}</h3>
      <p-row>
        <p-col>
          <p-request-alert v-model="responseError"></p-request-alert>
        </p-col>
      </p-row>
    </div>
    <div v-else-if="responseError">
      <p-request-alert v-model="responseError"></p-request-alert>
    </div>
    <div v-else-if="isRegisterSuccess">
      <p-alert type="success" :value="true">
        {{ $t('widget.registerCourse.courseRegisterSuccess', course.name, $format.localDate(course.startDate)) }}
      </p-alert>
    </div>
    <p-form v-else no-summary :model="model" :schema="model.schema">
      <!-- <div>
        <p-card style="z-index: 100" width="400px" class="absolute-top-right ma-3">
          <p-code>
            {{ model.requestSummary.toDTO() }}
          </p-code>
        </p-card>
      </div> -->
      <p-container>
        <h1>{{ course.name }}</h1>
      </p-container>
      <p-container class="personalInfo">
        <h4>{{ $t('widget.registerCourse.sectionPersonalData') }}</h4>
        <p-row dense>
          <p-col xs12>
            <p-row>
              <p-radio-field v-model="model.contactAddress.salutation" :true-value="0" name="gender" :label="$tEnum('widget.registerCourse.genderEnum', 0)"></p-radio-field>
              <p-radio-field v-model="model.contactAddress.salutation" :true-value="1" name="gender" :label="$tEnum('widget.registerCourse.genderEnum', 1)"></p-radio-field>
            </p-row>
          </p-col>
          <p-col xs12 sm6>
            <p-text-field v-model="model.contactAddress.firstName" :label="$t('widget.registerCourse.firstName')"></p-text-field>
          </p-col>
          <p-col xs12 sm6>
            <p-text-field v-model="model.contactAddress.lastName" :label="$t('widget.registerCourse.lastName')"></p-text-field>
          </p-col>
          <p-col xs12>
            <p-text-field v-model="model.contactAddress.street" :label="$t('widget.registerCourse.street')"></p-text-field>
          </p-col>
          <p-col xs2 sm1>
            <p-text-field v-model="model.contactAddress.postalCode" :label="$t('widget.registerCourse.postalCode')"></p-text-field>
          </p-col>
          <p-col xs10 sm11>
            <p-text-field v-model="model.contactAddress.city" :label="$t('widget.registerCourse.city')"></p-text-field>
          </p-col>
          <p-col xs6>
            <p-date-field v-model="model.contactAddress.dateOfBirth" :label="$t('widget.registerCourse.dateOfBirth')"></p-date-field>
          </p-col>
        </p-row>
      </p-container>
      <p-divider></p-divider>
      <p-container>
        <p-row dense>
          <p-col xs12>
            <h4>{{ $t('widget.registerCourse.sectionCommunication') }}</h4>
          </p-col>
          <p-col xs12 sm6>
            <p-phone-field v-model="model.contactAddress.mobilePhoneNumber" :label="$t('widget.registerCourse.mobilePrivate')"></p-phone-field>
          </p-col>
          <p-col xs12 sm6>
            <p-phone-field v-model="model.contactAddress.phoneNumber" :label="$t('widget.registerCourse.phonePrivate')"></p-phone-field>
          </p-col>
          <p-col xs12 sm6>
            <p-text-field v-model="model.contactAddress.eMail" type="email" :label="$t('widget.registerCourse.eMail')"></p-text-field>
          </p-col>
        </p-row>
        <p-row dense>
          <p-col xs12>
&nbsp;
          </p-col>
          <p-col xs12 sm6 class="correspondenceAddress">
            <p-col xs12>
              <h5>{{ $t('widget.registerCourse.sectionCorrespondenceAddress') }}</h5>
              <sup>&nbsp;</sup>
              <p-row dense>
                <p-radio-field v-model="model.correspondenceAddress.isDifferent" :true-value="false" name="correspondence" :label="$t('widget.registerCourse.privateAddress')"></p-radio-field>
                <p-radio-field v-model="model.correspondenceAddress.isDifferent" :true-value="true" name="correspondence" :label="$t('widget.registerCourse.institutionAddress')"></p-radio-field>
              </p-row>
            </p-col>
          </p-col>
          <p-col v-if="course.price !== undefined" xs12 sm6 class="invoiceAddress">
            <p-col xs12>
              <h5>{{ $t('widget.registerCourse.sectionInvoiceAddress') }}</h5>
              <sup>{{ $t('widget.registerCourse.memberPriceInfo') }}</sup>
              <p-row dense>
                <p-radio-field v-model="model.invoiceAddress.isDifferent" :true-value="false" name="invoice" :label="$t('widget.registerCourse.privateAddress')"></p-radio-field>
                <p-radio-field v-model="model.invoiceAddress.isDifferent" :true-value="true" name="invoice" :label="$t('widget.registerCourse.institutionAddress')"></p-radio-field>
              </p-row>
            </p-col>
          </p-col>
        </p-row>
      </p-container>
      <p-divider></p-divider>
      <template v-if="model.correspondenceAddress.isDifferent || model.invoiceAddress.isDifferent">
        <p-form :model="institutionModel" :schema="model.schemaInstitution">
          <p-container>
            <p-row dense>
              <p-col xs12>
                <h4>{{ $t('widget.registerCourse.sectionInstitution') }}</h4>
              </p-col>
              <p-col xs12>
                <p-text-field v-model="institutionModel.companyName" :label="$t('widget.registerCourse.companyName')"></p-text-field>
              </p-col>
              <p-col xs12>
                <p-text-field v-model="institutionModel.street" :label="$t('widget.registerCourse.street')"></p-text-field>
              </p-col>
              <p-col xs2 sm1>
                <p-text-field v-model="institutionModel.postalCode" :label="$t('widget.registerCourse.postalCode')"></p-text-field>
              </p-col>
              <p-col xs10 sm11>
                <p-text-field v-model="institutionModel.city" :label="$t('widget.registerCourse.city')"></p-text-field>
              </p-col>
              <p-col xs12 sm6>
                <p-phone-field v-model="institutionModel.phoneNumber" :label="$t('widget.registerCourse.phoneBusiness')"></p-phone-field>
              </p-col>
              <p-col xs12 sm6>
                <p-text-field v-model="institutionModel.eMail" :label="$t('widget.registerCourse.eMail')"></p-text-field>
              </p-col>
              <p-col xs12 sm6>
                <p-checkbox-group v-model="institutionModel.subjectArea" dense item-value="id" :items="model.subjectAreaList" :label="$t('widget.registerCourse.subjectArea')"></p-checkbox-group>
              </p-col>
            </p-row>
          </p-container>
        </p-form>
        <p-divider></p-divider>
      </template>
      <template v-if="specificQuestionsFormDefinition && specificQuestionsFormDefinition.fields.length > 0">
        <p-container>
          <p-row dense>
            <p-col xs12>
              <h4>{{ $t('widget.registerCourse.sectionSpecificQuestions') }}</h4>
            </p-col>
            <p-col xs12>
              <p-progress-linear v-if="!specificQuestionsFormDefinition" indeterminate></p-progress-linear>
              <p-dynamic-form
                v-model="model.registrationAnswers"
                :summary.sync="model.registrationAnswersSummary"
                :form-definition="specificQuestionsFormDefinition"
                no-contact-fields
                no-address-fields
              ></p-dynamic-form>
            </p-col>
          </p-row>
        </p-container>
        <p-divider></p-divider>
      </template>
      <p-container>
        <p-row dense>
          <p-col xs12>
            <h4>{{ $t('widget.registerCourse.sectionAdditionalInformation') }}</h4>
          </p-col>
          <p-col xs12>
            <p-textarea v-model="model.comment" :placeholder="$t('widget.registerCourse.comment')" :label="$t('widget.registerCourse.comment')"></p-textarea>
          </p-col>
          <template v-if="marketingQuestionsFormDefinition && marketingQuestionsFormDefinition.fields.length > 0">
            <p-col xs12>
              <p-progress-linear v-if="!marketingQuestionsFormDefinition" indeterminate></p-progress-linear>
              <p-dynamic-form
                v-model="model.marketingAnswers"
                :summary.sync="model.marketingAnswersSummary"
                :form-definition="marketingQuestionsFormDefinition"
                no-contact-fields
                no-address-fields
              ></p-dynamic-form>
            </p-col>
          </template>
        </p-row>
      </p-container>
      <p-divider></p-divider>
      <p-container>
        <p-row dense>
          <p-col xs12>
            <p-checkbox v-model="model.acceptNewsletter" :label="$t('widget.registerCourse.acceptNewsletter')"></p-checkbox>
          </p-col>
        </p-row>
      </p-container>
      <p-divider></p-divider>
      <p-container>
        <p-row dense>
          <p-col xs12 justify-end>
            <p-checkbox v-model="model.acceptGdpr" label="Slot">
              Ich habe die <a target="_blank" href="https://www.agogis.ch/agb">AGB</a> gelesen und akzeptiert
            </p-checkbox>
          </p-col>
          <p-col xs12 justify-end>
            <p-button :disabled="isSending" :loading="isSending" @click="sendForm">
              Anmelden
            </p-button>
          </p-col>
        </p-row>
      </p-container>
    </p-form>
  </div>
  <!-- End Agogis.Widget.registerCourse -->
</template>

<script lang="ts">
  import Vue from 'vue';
  import DynamicFormDefinitionResourceViewModel from '../../../services/v1/viewModel/resource/DynamicFormDefinitionResourceViewModel';
  import ApiPagingRequestViewModel from '../../../services/v1/viewModel/resource/ApiPagingRequestViewModel';
  import ContactAddressResourceViewModel from '../../../services/v1/viewModel/resource/ContactAddressResourceViewModel';
  import RegisterClassRequestViewModel from '../../../services/v1/viewModel/resource/RegisterClassRequestViewModel';
  import WidgetClassListResourceViewModel from '../../../services/v1/viewModel/resource/WidgetClassListResourceViewModel';
  import WidgetSearchClassRequestViewModel from '../../../services/v1/viewModel/resource/WidgetSearchClassRequestViewModel';

  export default Vue.extend({
    layout: 'layout-widget-form',
    data: () => ({
      isRegisterSuccess: false,
      isSending: false,
      isLoading: true,
      specificQuestionsFormDefinition: undefined as DynamicFormDefinitionResourceViewModel | undefined,
      marketingQuestionsFormDefinition: undefined as DynamicFormDefinitionResourceViewModel | undefined,
      institutionModel: new ContactAddressResourceViewModel(),
      model: new RegisterClassRequestViewModel().fromDTO({
        contactAddress: {},
        correspondenceAddress: { contactAddress: {}, isDifferent: false },
        invoiceAddress: { contactAddress: {}, isDifferent: false },
      } as any),
      courseId: -1,
      course: new WidgetClassListResourceViewModel(),
      responseError: undefined as any,
    }),
    watch: {
      'model.correspondenceAddress.isDifferent': {
        handler() {
          if (!this.model.correspondenceAddress.isDifferent) {
            this.model.correspondenceAddress.contactAddress = new ContactAddressResourceViewModel();
          } else {
            this.updateFromInstitutionModel();
          }
        },
      },
      'model.invoiceAddress.isDifferent': {
        handler() {
          if (!this.model.invoiceAddress.isDifferent) {
            this.model.invoiceAddress.contactAddress = new ContactAddressResourceViewModel();
          } else {
            this.updateFromInstitutionModel();
          }
        },
      },
      institutionModel: {
        deep: true,
        handler() {
          this.updateFromInstitutionModel();
        },
      },
    },
    async mounted() {
      const pp = this.$routerUtils.getQueryParams();
      if (pp.id !== undefined) {
        this.courseId = Number.parseInt(pp.id, 10);
        if (!Number.isNaN(this.courseId)) {
          await this.loadCourseInformation();
        } else {
          this.responseError = `Ungültige Kursnummer: ${pp.id}`;
        }
      } else {
        // TODO: Should this not be an error state, shown to the user?
        this.$log.error('No courseId given');
      }
    },
    methods: {
      updateFromInstitutionModel() {
        this.institutionModel.isCompany = true;
        if (this.model.correspondenceAddress.isDifferent) {
          this.model.correspondenceAddress.contactAddress = this.institutionModel;
          this.institutionModel.schema = this.model.schemaInstitution; // set schema when validation is needed
        }
        if (this.model.invoiceAddress.isDifferent) {
          this.model.invoiceAddress.contactAddress = this.institutionModel;
          this.institutionModel.schema = this.model.schemaInstitution; // set schema when validation is needed
        }
      },
      async sendForm() {
        if (this.model.correspondenceAddress.isDifferent || this.model.invoiceAddress.isDifferent) {
          // Needs to be validated separatly since it's not the same model, but schemas are kept in same place
          if (!this.institutionModel.validateWithSchema(this.model.schemaInstitution)) {
            // hotfix because of subjectArea is mandatory but not validated
            const ve = this.institutionModel.errors.find((e) => e.propName === 'subjectArea');
            if (ve !== undefined) {
              ve.message = `${Vue.$t('widget.registerCourse.subjectArea')}: ${ve.message}`;
            }
            return;
          }
        }
        if (!this.model.validate()) {
          return;
        }
        this.isSending = true;
        try {
          await this.$service.api.widgetClasses.widgetClassRegisterRequest(this.courseId, this.model);
          this.isRegisterSuccess = true;
        } catch (e: any) {
          this.responseError = e;
        }
        this.isSending = false;
      },
      async loadDynamicForms() {
        this.specificQuestionsFormDefinition = await this.$service.api.widgetClasses.getWidgetClassRegistrationQuestionsDefinition(this.courseId);
        this.marketingQuestionsFormDefinition = await this.$service.api.widgetClasses.getWidgetRegistrationMarketingQuestionsDefinition(this.courseId);
        const responseSubjectArea = await this.$service.api.widgetClasses.getWidgetSubjectAreas();
        this.model.subjectAreaList = responseSubjectArea.items;
      },
      async loadCourseInformation() {
        this.isLoading = true;
        await this.$guards.waitForAppLoad();
        try {
          const widgetSearchClassRequestVM = new WidgetSearchClassRequestViewModel();
          widgetSearchClassRequestVM.toccoFilter = `(pk == long:"${this.courseId}")`;
          const page = new ApiPagingRequestViewModel();
          page.pageSize = 1;
          widgetSearchClassRequestVM.paging = page;
          const response = await this.$service.v1.api.widgetClasses.getWidgetClasses(widgetSearchClassRequestVM);
          [this.course] = response.items.filter((i) => i.id === this.courseId);
          await this.loadDynamicForms();
          this.model.eventId = this.courseId;
        } catch (e : any) {
          this.responseError = e;
        }
        this.isLoading = false;
      },
    },
  });
</script>
