// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WidgetClassListModel from '../../model/widget-class-list-model';
import WidgetClassListDTO from '../dto/widget-class-list-dto';
import dtoSchema from '../schemas/widget-class-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import EventOccurenceModel from '../../model/event-occurence-model';

export default abstract class WidgetClassListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WidgetClassListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.name = data.name ?? undefined;
      this.abbreviation = data.abbreviation ?? undefined;
      this.startDate = data.startDate ?? undefined;
      this.endDate = data.endDate ?? undefined;
      this.city = data.city ?? undefined;
      this.imageUrl = data.imageUrl ?? undefined;
      this.imageThumbUrl = data.imageThumbUrl ?? undefined;
      this.price = data.price ?? undefined;
      this.registrationStatus = data.registrationStatus ?? undefined;
      this.registrationIndicator = data.registrationIndicator ?? undefined;
      this.detailUrl = data.detailUrl ?? undefined;
      this.eventType = data.eventType ?? undefined;
      this.registrationFormPDFUrl = data.registrationFormPDFUrl ?? undefined;
      this.occurences = data.occurences?.map((i) => EventOccurenceModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  name?: string;
  /**
  */
  abbreviation?: string;
  /**
  * @type {date-time}
  */
  startDate?: string;
  /**
  * @type {date-time}
  */
  endDate?: string;
  /**
  */
  city?: string;
  /**
  */
  imageUrl?: string;
  /**
  */
  imageThumbUrl?: string;
  /**
  * @type {double}
  */
  price?: number;
  /**
  */
  registrationStatus?: string;
  /**
  */
  registrationIndicator?: string;
  /**
  */
  detailUrl?: string;
  /**
  * @type {int64}
  */
  eventType?: number;
  /**
  */
  registrationFormPDFUrl?: string;
  /**
  */
  occurences?: EventOccurenceModel[];

  static toModel(dto: DeepPartial<WidgetClassListDTO>): WidgetClassListModel;
  static toModel(dto: DeepPartial<WidgetClassListDTO> | undefined | null): WidgetClassListModel | undefined;
  static toModel(dto: DeepPartial<WidgetClassListDTO> | undefined | null): WidgetClassListModel | undefined {
    return dto ? new WidgetClassListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WidgetClassListModel> | WidgetClassListModel): WidgetClassListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      name: unwrapped.name,
      abbreviation: unwrapped.abbreviation,
      startDate: unwrapped.startDate,
      endDate: unwrapped.endDate,
      city: unwrapped.city,
      imageUrl: unwrapped.imageUrl,
      imageThumbUrl: unwrapped.imageThumbUrl,
      price: unwrapped.price,
      registrationStatus: unwrapped.registrationStatus,
      registrationIndicator: unwrapped.registrationIndicator,
      detailUrl: unwrapped.detailUrl,
      eventType: unwrapped.eventType,
      registrationFormPDFUrl: unwrapped.registrationFormPDFUrl,
      occurences: unwrapped.occurences,
    } as WidgetClassListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WidgetClassListModel, can not map to WidgetClassListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
