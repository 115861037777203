// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import EventOccurenceSchema from './event-occurence-schema';

const schema = {
  $id: '/widget-class-list-schema',
  type: 'object',
  required: ['id'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    name: {
      type: 'string',
      nullable: true,
    },
    abbreviation: {
      type: 'string',
      nullable: true,
    },
    startDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    endDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    city: {
      type: 'string',
      nullable: true,
    },
    imageUrl: {
      type: 'string',
      nullable: true,
    },
    imageThumbUrl: {
      type: 'string',
      nullable: true,
    },
    price: {
      type: 'number',
      nullable: true,
    },
    registrationStatus: {
      type: 'string',
      nullable: true,
    },
    registrationIndicator: {
      type: 'string',
      nullable: true,
    },
    detailUrl: {
      type: 'string',
      nullable: true,
    },
    eventType: {
      type: 'integer',
      nullable: true,
    },
    registrationFormPDFUrl: {
      type: 'string',
      nullable: true,
    },
    occurences: {
      type: 'array',
      nullable: true,
      items: { ...EventOccurenceSchema },
    },
  },
} as IJSONSchema;

export default schema;
