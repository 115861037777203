// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import EventOccurenceResourceViewModel from '../../../../viewModel/resource/EventOccurenceResourceViewModel';
import WidgetClassListResourceViewModel from '../../../../viewModel/resource/WidgetClassListResourceViewModel';
import WidgetClassListResourceDTO from '../../dto/resource/WidgetClassListResourceDTO';

export default abstract class WidgetClassListResourceModel extends BaseModel<WidgetClassListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  */
  get abbreviation() { return this.dto.abbreviation; }
  set abbreviation(value) { this.dto.abbreviation = value; }
  /**
  * @type {date-time}
  */
  get startDate() { return this.dto.startDate; }
  set startDate(value) { this.dto.startDate = value; }
  /**
  * @type {date-time}
  */
  get endDate() { return this.dto.endDate; }
  set endDate(value) { this.dto.endDate = value; }
  /**
  */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }
  /**
  */
  get imageUrl() { return this.dto.imageUrl; }
  set imageUrl(value) { this.dto.imageUrl = value; }
  /**
  */
  get imageThumbUrl() { return this.dto.imageThumbUrl; }
  set imageThumbUrl(value) { this.dto.imageThumbUrl = value; }
  /**
  * @type {double}
  */
  get price() { return this.dto.price; }
  set price(value) { this.dto.price = value; }
  /**
  */
  get registrationStatus() { return this.dto.registrationStatus; }
  set registrationStatus(value) { this.dto.registrationStatus = value; }
  /**
  */
  get registrationIndicator() { return this.dto.registrationIndicator; }
  set registrationIndicator(value) { this.dto.registrationIndicator = value; }
  /**
  */
  get detailUrl() { return this.dto.detailUrl; }
  set detailUrl(value) { this.dto.detailUrl = value; }
  /**
  * @type {int64}
  */
  get eventType() { return this.dto.eventType; }
  set eventType(value) { this.dto.eventType = value; }
  /**
  */
  get registrationFormPDFUrl() { return this.dto.registrationFormPDFUrl; }
  set registrationFormPDFUrl(value) { this.dto.registrationFormPDFUrl = value; }
  /**
  */
  get occurences() { return this.getArrayModels(() => this.dto.occurences, EventOccurenceResourceViewModel)!; }
  set occurences(value) { this.setArrayModels(() => this.dto.occurences, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.name = qs.name;
    this.dto.abbreviation = qs.abbreviation;
    this.dto.startDate = qs.startdate;
    this.dto.endDate = qs.enddate;
    this.dto.city = qs.city;
    this.dto.imageUrl = qs.imageurl;
    this.dto.imageThumbUrl = qs.imagethumburl;
    value = Number.parseFloat(qs.price);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.price = value;
    this.dto.registrationStatus = qs.registrationstatus;
    this.dto.registrationIndicator = qs.registrationindicator;
    this.dto.detailUrl = qs.detailurl;
    value = Number.parseFloat(qs.eventtype);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.eventType = value;
    this.dto.registrationFormPDFUrl = qs.registrationformpdfurl;
  }
}
